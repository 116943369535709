import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "../components/Footer";
import { Styles } from "../styles/styles";

const Home = lazy(() => import("../pages/Home"));
const Portfolio = lazy(() => import("../pages/Portfolio"));
const Viewer = lazy(() => import("../pages/Viewer"));
const ObjViewer = lazy(() => import("../pages/ObjViewer"));
const ContactPage = lazy(() => import("../pages/Contact"));
const Results = lazy(() => import("../pages/Results"));
const GSplatViewer = lazy(() => import("../pages/GSplatViewer"));
const Login = lazy(() => import("../pages/Login"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Order = lazy(() => import("../pages/Order"));

const Router = () => {
  return (
    <Suspense fallback={null}>
      <Styles />
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/portfolio" element={<Portfolio/>} />
            <Route path="/contact" element={<ContactPage/>} />
            {/* <Route path="/viewer" element={<ObjViewer/>} /> */}
            <Route path="/login" element={<Login/>} />
            <Route path="/dashboard/:user_id" element={<Dashboard/>} />
            <Route path="/viewer/:rec_id" element={<Viewer/>} />
            <Route path="/results/:rec_id" element={<Results/>} />
            <Route path="/order/:coupon_code?" element={<Order/>} />
        </Routes>
        <Footer />  
    </Suspense>
  );
};

export default Router;
